import { LOGIN_SET_EMAIL_OR_PASSWORD, LOGIN_SET_ERROR_LIST } from "~/actions/action-types";

const INITIAL_STATE = {
    email: "",
    password: "",
    error_list: {}
};

const LoginReducer = (state = INITIAL_STATE, action) => {
    let newState = Object.assign({}, state);
    if (action.type == LOGIN_SET_EMAIL_OR_PASSWORD) {
        if (action.field === "email") {
            newState.email = action.value;
        }
        else if (action.field === "password") {
            newState.password = action.value;
        }
        return newState;
    }
    if (action.type === LOGIN_SET_ERROR_LIST) {
        if (action.mode == "add") {
            newState.error_list = { ...newState.error_list, [action.field]: action.message };
        }
        else if (action.mode == "delete") {
            const new_error_list = newState.error_list;
            delete new_error_list[action.field];
            newState.error_list = { ...new_error_list };
        }
        return newState;
    }
    return state;
};

export default LoginReducer;