import { useState, useEffect } from "react";
import ArrowCircleSvg from "~/svg/arrow-circle.svg";
const Testimonials = () => {
	const slider_width = screen.width < 600 ? 300 : 500;
	const [current_index, setCurrentIndex] = useState(0);
	useEffect(() => {
		const intVal = setInterval(() => {
			let indexVal;
			if (current_index === data.length - 1) {
				indexVal = 0;
			} else {
				indexVal = current_index + 1;
			}
			setCurrentIndex(indexVal);
		}, 5000);
		return () => clearInterval(intVal);
	}, [current_index]);
	const data = [
		{
			message:
				"Healthmug has helped me to start selling online. Their seller panel is very easy to work with and my earnings have also increased significantly.",
			by: "Shivam Homeo Pharmacy",
			place: "Delhi"
		},
		{
			message:
				"Healthmug solved a very big problem for us i.e., discoverability. We were not getting enough orders simply because people didn't know about us. Now we get orders regularly and we are seeing a better footfall on our store. ",
			by: "Gupta Pharmacy",
			place: "Delhi"
		},
		{
			message:
				"We are very pleased with Healthmug and its team. Everything from seller panel to payments is smooth and we are very happy with the business that Healthmug brings to us.",
			by: "Okhla General Pharmacy",
			place: "Delhi"
		}
	];

	return (
		<section className="pt-6 pb-14 bg-white">
			<h2 className="sm:text-3xl font-medium text-xl text-gray-600 text-center">Testimonials</h2>
			<div className="mx-auto mt-6 relative" style={{ width: slider_width + "px" }}>
				<div className="overflow-hidden ">
					<div
						className="flex transition-transform duration-700"
						style={{ width: slider_width * data.length + "px", transform: `translateX(-${current_index * slider_width}px)` }}
					>
						{data.map((item, i) => (
							<div key={i} className="text-center" style={{ flex: "0 0 " + slider_width + "px" }}>
								<p className="text-gray-600 mb-6 text-md">{item.message}</p>
								<p className="font-medium text-sm italic">-{item.by}</p>
								<p className="text-gray-medium text-sm">{item.place}</p>
							</div>
						))}
					</div>
				</div>
				<div className="absolute -left-6 -right-6 top-1/2 -mt-8 flex items-center justify-between text-gray-medium">
					<a onClick={() => setCurrentIndex(current_index == 0 ? data.length - 1 : current_index - 1)}>
						<ArrowCircleSvg className="h-5 w-5 fill-current" style={{ transform: "rotate(180deg)" }} />
					</a>
					<a onClick={() => setCurrentIndex(current_index == data.length - 1 ? 0 : current_index + 1)}>
						<ArrowCircleSvg className="h-5 w-5 fill-current" />
					</a>
				</div>
			</div>
			<div className="flex justify-center absolute w-full">
				{data.map((item, i) => (
					<a className="h-4 w-4 mx-0.5 items-center justify-center" key={i} onClick={() => setCurrentIndex(i)}>
						<div
							style={{
								width: "5px",
								height: "5px",
								borderRadius: "50%",
								transform: current_index === i ? "scale(1.5)" : null
							}}
							className={`transition-transform duration-500 ${current_index === i ? "bg-warning " : "bg-gray-medium"}`}
						/>
					</a>
				))}
			</div>
		</section>
	);
};

export default Testimonials;
