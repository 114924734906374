import thunk from "redux-thunk";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import "~/styles/style.css";

import LoginScreen from "~/screens/login/login-screen";

import LoginReducer from "~/reducers/login-reducer";

const store = createStore(LoginReducer, applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<LoginScreen />
	</Provider>,
	document.getElementById("pageRoot")
);
