import { useState } from "react";
import { connect } from "react-redux";

import { ProjectUrl } from "~/env";

import Input from "@bit/healthmug.admin.input";
import Button from "@bit/healthmug.admin.button";

import Testimonials from "~/components/common/testimonials";

import { useLoader } from "~/actions/custom-hooks";
import { ForgotPasswordValidation } from "~/actions/forgot-password-action";
import { LoginSetEmailOrPassword, LoginOnFocus, LoginCheckValidation } from "~/actions/login-action";

const LoginScreen = (props) => {
	const [loader, startLoader, endLoader] = useLoader(false);
	const [message, setMessage] = useState(null);

	const logIn = () => {
		startLoader();
		props
			.Login_Check_Validation()
			.then(() => {
				window.location.href = `${ProjectUrl}/analytics/dashboard`;
				endLoader();
			})
			.catch(endLoader);
	};

	const forgotPassword = () => {
		props
			.Forgot_Password_Validation(props.email)
			.then(() => setMessage("Reset password link has been sent to your email"))
			.catch(() => null);
	};

	return (
		<>
			<section className="bg-primary">
				<div className="relative max-w-7xl mx-auto overflow-hidden">
					<img src="/images/login/hero.png" className="max-w-screen-md height-auto absolute sm:right-0 sm:left-auto " />
					<div className="relative">
						<div className="pt-4">
							<img src="/images/logo-full-light.png" className="w-60 height-auto" />
						</div>
						<div
							className="max-w-sm mt-8 sm:ml-40 mb-8 sm:mx-auto mx-6 relative shadow-lg p-4 rounded"
							style={{ backgroundColor: "rgba(255, 255, 255, 0.95)" }}
						>
							<h1 className="text-3xl text-gray-500 mt-3 font-semibold">Sell and Grow on Healthmug</h1>
							<p className="text-gray-500 mt-2 mb-3 mt-4">Sign in and start managing your store</p>

							<div>{message ? <p>{message}</p> : null}</div>

							<div className="mb-4 mt-6">
								<label className="text-gray-medium text-sm">Email ID</label>
								<Input
									name="email"
									onFocus={() => props.Login_On_Focus("email")}
									value={props.email}
									onChange={(e) => props.Login_Set_Email_Or_Password(e.target.name, e.target.value)}
									placeholder="User ID"
									error={props.error_list.email ? props.error_list.email : null}
									required
								/>
							</div>
							<div className="mb-4">
								<label className="text-gray-medium text-sm">Password</label>
								<Input
									name="password"
									onKeyPress={(e) => (e.charCode == 13 ? logIn() : null)}
									onChange={(e) => props.Login_Set_Email_Or_Password(e.target.name, e.target.value)}
									placeholder="Password"
									type="password"
									value={props.password}
									onFocus={() => props.Login_On_Focus("password")}
									error={props.error_list.password ? props.error_list.password : null}
									required
								/>
							</div>
							<Button onClick={logIn} loader={loader} disabled={loader} warning block large className="mt-10">
								Login
							</Button>
							<div className="text-right">
								<a onClick={forgotPassword} className="text-primary text-sm">
									Forgot Password?
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="py-8">
				<div className="relative max-w-7xl mx-auto overflow-hidden text-center sm:p-0 px-4">
					<h2 className="sm:text-3xl font-medium text-xl text-gray-600">Sell and Grow with Healthmug</h2>
					<h3 className="text-gray-600 text-md">Sell your products on the largest AYUSH marketplace in the country</h3>
					<div className="grid sm:grid-cols-4 grid-cols-2 grid-flow-row gap-4 mb-4">
						<div className="mt-4">
							<img src="/images/login/local-customer.png" className="w-40 height-auto inline-block" />
							<h4 className="font-medium sm:text-md text-gray-dark">Local Customers</h4>
							<p className="text-sm text-gray-600">Customer's in your locality</p>
						</div>
						<div className="mt-4">
							<img src="/images/login/quick-delivery.png" className="w-40 height-auto inline-block" />
							<h4 className="font-medium text-md text-gray-dark">Quick Delivery</h4>
							<p className="text-sm text-gray-600">Short order cycle</p>
						</div>
						<div className="mt-4">
							<img src="/images/login/easy-payment.png" className="w-40 height-auto inline-block" />
							<h4 className="font-medium text-md text-gray-dark">Easy Payments</h4>
							<p className="text-sm text-gray-600">Fastest payment process in Industry</p>
						</div>
						<div className="mt-4">
							<img src="/images/login/cash-in-hand.png" className="w-40 height-auto inline-block" />
							<h4 className="font-medium text-md text-gray-dark">Collect Payments First-Hand</h4>
							<p className="text-sm text-gray-600">COD payments collected by Seller</p>
						</div>
					</div>
				</div>
			</section>
			<section className="py-8 bg-yellow-50">
				<div className="relative max-w-7xl mx-auto overflow-hidden text-center sm:p-0 px-4">
					<h2 className="sm:text-3xl font-medium text-xl text-gray-600">Why Healthmug?</h2>
					<div className="mb-4 mt-2">
						<div className="text-center">
							<div className="mt-6 mx-3 inline-block align-top">
								<div className="max-w-sm mx-auto bg-white py-8 px-16 shadow-md rounded-xl">
									<img src="/images/login/customer-centric.png" className="w-28 height-auto inline-block" />
									<h4 className="font-medium sm:text-lg text-gray-dark mt-5">Customer Centric</h4>
									<p className="sm:text-md text-sm text-gray-600" style={{ minHeight: "100px" }}>
										Ensuring the happiness and health of our customers with competitive pricing and fast delivery.
									</p>
								</div>
							</div>
							<div className="mt-6 mx-3 inline-block align-top">
								<div className="max-w-sm mx-auto bg-white py-8 px-16 shadow-md rounded-xl">
									<img src="/images/login/ease-of-sale.png" className="w-28 height-auto inline-block" />
									<h4 className="font-medium sm:text-lg text-gray-dark mt-5">Ease of Sale</h4>
									<p className="sm:text-md text-sm text-gray-600" style={{ minHeight: "100px" }}>
										Very short order cycle. Hassle-free pickup and delivery are also available.
									</p>
								</div>
							</div>
						</div>
						<div className="text-center">
							<div className="mt-6 mx-3 inline-block align-top">
								<div className="max-w-sm mx-auto bg-white py-8 px-16 shadow-md rounded-xl">
									<img src="/images/login/local-delivery.png" className="w-28 height-auto inline-block" />
									<h4 className="font-medium sm:text-lg text-gray-dark mt-5">Localised Deliveries</h4>
									<p className="sm:text-md text-sm text-gray-600" style={{ minHeight: "100px" }}>
										Get access to a local customer base who engage with your business regularly.
									</p>
								</div>
							</div>
							<div className="mt-6 mx-3 inline-block align-top">
								<div className="max-w-sm mx-auto bg-white py-8 px-16 shadow-md rounded-xl">
									<img src="/images/login/support.png" className="w-28 height-auto inline-block" />
									<h4 className="font-medium sm:text-lg text-gray-dark mt-5">Dedicated support</h4>
									<p className="sm:text-md text-sm text-gray-600" style={{ minHeight: "100px" }}>
										Get personalized seller support from the Healthmug team and get all your queries solved as soon as possible.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="py-8" style={{ background: "linear-gradient(#eff6ff 60%, white 40%" }}>
				<div className="relative max-w-7xl mx-auto text-center sm:p-0 px-4">
					<h2 className="sm:text-3xl font-medium text-xl text-gray-600">How to sell on Healthmug?</h2>
					<div className="sm:grid sm:grid-cols-3 sm:grid-flow-row sm:gap-4 mt-8  bg-white rounded-lg shadow-md">
						<div className="mt-4">
							<div className="max-w-sm mx-auto py-8 px-16">
								<img src="/images/login/product-listing.png" className="w-28 height-auto inline-block" />
								<h4 className="font-medium sm:text-lg text-gray-dark mt-5">List your products</h4>
								<p className="sm:text-md text-sm text-gray-600">Go live with your products via an easy-to-use seller panel.</p>
							</div>
						</div>
						<div className="mt-4">
							<div className="max-w-sm mx-auto py-8 px-16">
								<img src="/images/login/order-delivery.png" className="w-28 height-auto inline-block" />
								<h4 className="font-medium sm:text-lg text-gray-dark mt-5">Deliver your order</h4>
								<p className="sm:text-md text-sm text-gray-600">Receive orders and deliver them easily within your locality.</p>
							</div>
						</div>
						<div className="mt-4">
							<div className="max-w-sm mx-auto py-8 px-16">
								<img src="/images/login/get-payment.png" className="w-28 height-auto inline-block" />
								<h4 className="font-medium sm:text-lg text-gray-dark mt-5">Get paid on time</h4>
								<p className="sm:text-md text-sm text-gray-600">
									Receive payments with the fastest and safest payment process in the industry.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Testimonials />
			<footer className="py-8 border-top border-gray-light">
				<div className="max-w-7xl mx-auto sm:p-0 px-4 text-center">
					<p className="text-sm text-gray-600">Copyright© 2022. All rights reserved.</p>
					<p className="text-md font-medium text-gray-600 mt-2">
						In compliance with Drug and Cosmetic Act and Rules, we dont sell schedule H, H1, X or any habit forming drugs.
					</p>
					<p className="mt-3 text-gray-medium">
						<span className="font-semibold text-2xl text-gray-500">Healthmug.</span>com
					</p>
				</div>
			</footer>
		</>
	);
};

const mapStateToProps = (state) => ({
	email: state.email,
	password: state.password,
	error_list: state.error_list
});
const mapDispatchToProps = (dispatch) => ({
	Login_On_Focus: (field) => dispatch(LoginOnFocus(field)),
	Forgot_Password_Validation: (email) => dispatch(ForgotPasswordValidation(email)),
	Login_Check_Validation: () => dispatch(LoginCheckValidation()),
	Login_Set_Email_Or_Password: (field, value) => dispatch(LoginSetEmailOrPassword(field, value))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
